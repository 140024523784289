<template>
  <div>
    <v-autocomplete
      :value="selectedRoles"
      :items="roles"
      clearable
      item-text="name"
      return-object
      @change="emitInput"
      v-bind="$props"
      :loading="isLoading"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { findAll } from "@/services/roles-service";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      roles: [],
      selectedRoles: this.value,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  created() {
    this.findRoles();
  },
  methods: {
    emitInput(value) {
      this.$emit("input", value);
    },
    async findRoles() {
      const { data } = await findAll();
      this.roles = data;
    },
  },
};
</script>

<style></style>
